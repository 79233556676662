  <template>
    <div>
      <v-container>
        <!-- <div style="margin-top: 20px; margin-bottom: 20px; margin-left: auto; margin-right: auto; height: 200px; width: 80%; overflow-y: auto; overflow-x: auto; border-style: solid; border-width: 1px; padding-top: 10px; padding-left: 10px; padding-bottom: 10px; padding-right: 10px"> -->
        <!-- <v-card elevation="6" height="200px" style="margin-left: 20px; margin-right: 20px; margin-top: 20px; margin-bottom: 20px; padding-left: 10px; padding-top: 10px; padding-bottom: 10px; padding-right: 10px; overflow-y: auto"> -->
        
          
        <v-row dense>
          <v-col cols="auto" style="margin-left: auto; margin-right: auto">
            <h2 class="ml-4 mr-4 mb-0 font-weight-bold">{{cuestionario.Titulo}}</h2>
          </v-col>
        </v-row>
    
        <v-row dense>
          <v-col cols="auto" style="margin-left: auto; margin-right: auto">
            <img v-bind:src="'https://d23esi1h40dfmi.cloudfront.net/wp-content/uploads/2021/06/08083209/Logo_2_tintas-e1623141346682.png'" />
          </v-col>
        </v-row>
    
        <v-row dense>
          <v-col cols="auto" style="margin-left: auto; margin-right: auto">
            <p class="ml-8 mr-8">Hola, <span style="font-weight: bold">{{ this.$store.state.per.PerNom }}</span></p>
          </v-col>
        </v-row>
      
        <v-row dense>
          <v-col cols="auto" style="margin-left: auto; margin-right: auto">
            <v-row dense>
              <p class="ml-8 mr-8" v-html="cuestionario.Descripcion" style="text-align: center"></p>
            </v-row>
          </v-col>
          
        </v-row>
        
        <template>
          <v-row dense>
            <v-col cols="auto" style="margin-left: auto; margin-right: auto">
                <v-row dense>
                    <label for="amount">&nbsp;<b>Introduce el importe con el que quieras participar en multiplos de 5€:</b></label>
                
                </v-row>
                <v-row>
                    <br>
                </v-row>  
                <v-row>
                    <v-text-field
                    class="mb-n7 no-arrows" outlined dense
                    type="number" min="0"
                    v-model="amount"
                    label="Cantidad"
                    >
                      <v-icon class="mb-n1" slot="append" @click.stop="changeCantidad(amount,5,true,true)">mdi-plus</v-icon>
                      <v-icon class="mb-n1" slot="prepend" @click.stop="changeCantidad(amount,-5,true,true)">mdi-minus</v-icon>
                    </v-text-field>
                </v-row>
            </v-col>
            
          </v-row>
        </template>
        
        <!-- </div> -->
      <br>
      <br>
      <v-row dense >
        <v-col cols="auto" style="margin-left: auto; margin-right: auto">
          <v-btn
            color="primary"
            :loading="loading"
            @click="onClickSaveData"
          >
            Guardar participación
          </v-btn>
        </v-col>
      </v-row>
    
        
  
      </v-container>
    </div>
  </template>
  
  <style>
  /* Chrome, Safari, Edge, Opera */
  .no-arrows>div>div>div>input::-webkit-outer-spin-button,
  .no-arrows>div>div>div>input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  }
  
  /* Firefox */
  .no-arrows>div>div>div>input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
  }
  </style>

  <script>
    import axios from "axios"
    import { mapState } from "vuex";
    // import router from '../router'
    export default {
      computed:{
          ...mapState(['empId', 'perId', 'perAux', 'esEmpleado', 'urlRaiz'])
      },
      data: () => ({
        loading: false,
        cuestionario: {
        Respuestas: [],
      },
      amount: 0,
      respuestas:{
        cantidad: { PreguntasCuestionarioId: 9, respuestaUsuario:[], text: 'Cantidad', width: 50, sortable: false, value: 'amount' }
      }

        // mostrarAlergenos: false
      }),
  
      methods: {
        // validate () {
        //   this.$refs.form.validate()
        //   if (this.valid)
        //   {
        //       this.enviar();
        //   }
        // },
        changeCantidad(item, factor = 1, isNew = false, forceUpdate = false){
            const itemNumber = isNaN(item) ? 0 : Number(item);
            const result = itemNumber + factor;
            if(isNew) this.amount = result;
            else this.amount = result;
            if (this.amount < 0) this.amount = 0
            if (forceUpdate) this.$forceUpdate();
            
        },
        getDataFromApi() {
          this.loading = true;
  
          return new Promise((resolve) => {
            const controllerParameters = {
              Titulo: 'LOTERÍA NAVIDAD 2024',
              PerId: this.$store.state.per.PerId,
              Navidad: true

            };
  
            const AuthToken = localStorage.getItem('token');
            axios({ method: "POST", "url": this.urlRaiz + "/api/cuestionario/get", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
            .then(result => {
              const item = result.data.Cuestionario;
              this.amount = result.data.Respuesta;
              setTimeout(() => {
                  this.loading = false;
                  resolve({
                    item
                  })
              }, 1000)
            })
            .catch(error => {
              if(error != null) {
                  this.loading = false;
                  alert('Error al cargar el cuestionario');
              }
            });
          })
        },
        postDataToApi() {
          this.loading = true;
  
          return new Promise((resolve) => {
            const controllerParameters = {
              Respuestas: [ this.respuestas.cantidad ],
              CuestionarioId: this.cuestionario.CuestionarioId,
              PerId: this.perId,
              DirId: 0
                //DirId: this.perAux != null && this.perAux.Dir != null && this.perAux.Dir.length > 0 ?
                //this.perAux.Dir[0].DirId : null
            };
  
            const AuthToken = localStorage.getItem('token');
            axios({ method: "POST", "url": this.urlRaiz + "/api/cuestionario/respuesta/save", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
            .then(result => {
              const item = result.data.Success;
  
              setTimeout(() => {
                  this.loading = false;
                  resolve({
                    item
                  })
              }, 1000)
            })
            .catch(error => {
              if(error != null) {
                  this.loading = false;
                  alert('Error al guardar el cuestionario');
              }
            });
          })
        },
        loadData(){
          this.getDataFromApi()
            .then(data => {
              this.cuestionario = data.item;
            });
        },
        onClickSaveData() {
          if (!this.esEmpleado) {
            alert('INSCRIPCION NO GUARDADA CORRECTAMENTE - Estas intentando guardar la participación desde el usuario de la tienda, inicia sesión desde tu intranet PERSONAL para grabar la inscripción');
            this.$router.push('/');
            return;
          }
          if (this.amount % 5 !=0) {
            alert('El importe de la participación debe ser múltiplo de 5')
            return;
          }
  
          this.respuestas.cantidad.respuestaUsuario = this.amount.toString();
          this.postDataToApi()
            .then(data => {
              const success = data.item;
              if (success)
              {
                alert('Muchas gracias por tu participación, se ha guardado correctamente');
                this.$router.push('/');
              }
            });
        },
  
      },
      mounted() {
        this.loadData();
      }
    }
  </script>